// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("packs/time_zone_onboarding");
require("packs/add_option");
require("packs/accept_match");
require("packs/read_match_messages");
require("packs/active_tab_calendar");
require("packs/amount_of_hours");
require("packs/chosen_slot");
require("packs/match_clickable_row");
require("packs/admin_table_search");
require("underscore")
require("judge/judge")

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("bootstrap");
require("flatpickr")

import 'scss/site'
import flatpickr from "flatpickr";

document.addEventListener("turbolinks:load", () => {
  $('[data-tooltip-display="true"]').tooltip(),
    flatpickr(".flatpickr", {})
  add_eventlisteners()
})


function add_eventlisteners() {
  $('.auto_submit').click(auto_submit)
}

function auto_submit() {
  const data =$(this).data()
  const endpoint = data['submitTo']
  let payload = {}
  payload[this.name] = $(this).is(':checked')
  $.post(endpoint, {...payload, ...data['payload']})
}


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
