$(document).on('turbolinks:load', function () {
    $('#new_lesson').on('change', function (e) {
        let match = $(e.target).find(':selected').attr('data-match-id')
        $.ajax({
            url: '../matches/chosen_slot',
            data: { id: match },
            method: 'POST',
            dataType: 'json',
            success: function (data) {
                $(`.chosen_slot_target`).text(data.time_slot)
            },
        })
    })
})