function CreateNewSelect(num) {
    daysArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    hoursArray = Array(48).fill(0).map((x, y) => x + y).map(hour => {
        let new_hour = hour/2
        if(new_hour % 1 === 0)
        {
            return `${new_hour}:00`
        }else {
            return `${new_hour - 0.5}:30`
        }
    })
    hoursOptions = hoursArray.map(hour => `<option value='${hour}'>${hour}</option>`)
    daysOptions = daysArray.map(day => `<option value='${day}'>${day}</option>`)
    hoursSelector =
        (`<select class="ml-3 bg-secondary text-primary border-0 p-3" name="hours[]" id="hours_">
            <option value="">Hour</option>
        ${hoursOptions.join('')}
        </select>
`)
    daysSelector =
        (`<select class="ml-3 bg-secondary text-primary border-0 p-3" name="days[]" id="days_">
            <option value="">Day</option>
        ${daysOptions.join('')}
        </select>
`)
    timeSlotField = (`<div class="form-group col-4">
                            <div class="field">
                                <span class="text-muted"'>#${num}</span>
                                ${daysSelector}
                                ${hoursSelector}
                            </div>
                      </div>
`)
    return timeSlotField
}

function RemoveSelect() {
    $('.time-slots-container div.form-group:nth-last-of-type(1)').remove()
}

$(document).on('turbolinks:load', function () {
    let append_target = $('.time-slots-container');
    let remove_button = $('#remove-option')
    remove_button.hide()
    num = $('form').attr('data-amount')
    $('#add-option').on("click", append_target, function (e) {
        num++
        if(remove_button.is(':hidden')) {
            $('#remove-option').show()
        }
        append_target.append(`${CreateNewSelect(num)}`)
        e.preventDefault()
        $('form').attr('data-amount', num)
    })
    remove_button.on("click", append_target, function (e) {
        if(num > 3) {
            num--
            RemoveSelect()
            $('form').attr('data-amount', num)
            if(num <= 3) {
                $('#remove-option').hide()
            }
        }
        e.preventDefault()
    })
    remove_button.on('change')
});

