$(document).on('turbolinks:load', function () {
    let read_match_route = $('.match-tabs').attr('data-submit-to')
    $('a[data-toggle="tab"]').on('show.bs.tab', function (e) {
        let match = $(e.target).attr('data-id')
        $.post(read_match_route, {id: match})
    })
    let active_tab = $('.match-tab.active')
    active_tab.on('click', function (e) {
        let id = $(active_tab).attr('data-id')
        $.post(read_match_route, {id: id})
    })

})