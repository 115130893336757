$(document).on('turbolinks:load', function () {
    $('.new_order').on('change', function (e) {
        if($(e.target).find(':selected').attr('data-duration')) {
            let target_product = $(this).attr('data-id')
            let hours = $(this).attr('data-hours')
            let duration = $(e.target).find(':selected').attr('data-duration')
            $(`#amount-of-lessons-${target_product}`).text(`Get ${hours} lessons of 60 minutes , or ${hours * 2} lessons of 30 minutes.
`)
        }
    })
})